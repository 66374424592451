@import "../../../assets/variables.scss";

.zol-export-content{
    background-color: $off-white-color;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
h4{
    margin: 20px 0px 10px 0px;
}

.zol-tooltip{
    width: 24px;
    position: relative;
    height: auto;
    fill : $primary-color;
    cursor: pointer;
}

.zol-tooltip .zol-tooltiptext {
    display: none;
    font-size: 16px;
}

.zol-tooltip:hover .zol-tooltiptext {
    display: block;
    position: absolute;
    width: 600px;
    right: 25px;
    top: -15px;
    background-color: white;
    border: 1px solid #aaa;
    color: #4B4B4B;
    text-align: left;
    font-style: normal;
    border-radius: 6px;
    padding: 5px 10px;
}

.zol-manager-table{
    font-weight: bold;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: center;
    margin: 30px 0px;
    
    td{
        font-weight: bold;
        border: 1px solid $dark-color;
        text-align: center;
        padding: 10px;
    }

    .header-column{
        background-color: $off-white-color;    
        text-align: left;
        p{
            font-weight:400;
            margin: 0px;
        }
    }
    .header-column-table2{ 
        text-align: left;
        width: 40%;
        p{
            font-weight:400;
            margin: 0px;
        }
    }
    .header-row{
        background-color: $off-white-color;
    }

    .no-threshold{
        background-color: $off-white-color;
    }
    .threshold{
        font-weight: 400;
    }
}

