@import "../../../assets/variables";

.button{
    border: none;
    padding: 10px 25px;
    border-radius: 7.5px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    &:disabled{
        opacity: .75;
    }
    &:hover{
        opacity: .9
    }

    >img, >svg{
        vertical-align: middle;
    }
}

.primary{
    background-color: $primary-color;
    color: $white-color;
    &.disabled{
        color: rgba($primary-color, 0.5);
    }
}

.error{
    background-color: rgba($brand-red-color, .15);
    color: $brand-red-color;
}

.disabled{
    background-color: $primary-color;
    opacity: .5;
    color: $white-color;
    pointer-events: none;
    cursor: default;
    &:hover{
        opacity: .5;
    }
}

.off-white{
    background-color: $off-white-color;
    color: $primary-color;
}

.medium{
    background-color: #e2e7fc;
    color: $primary-color;

    &.disabled{
        color: rgba($primary-color, 0.5);
    }
}