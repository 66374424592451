@import "../../assets/variables";

.zol-select{
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #fff;
  width: 100%;
  height: 20px;
  .zol-selected{
    height: 20px;
    padding: 3px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $off-white-color;
    border: 1px solid rgb(118, 118, 118);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $medium-color;
    overflow: hidden;
  }
  .zol-select-options{
    max-height: 160px;
    overflow: auto;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    color: $medium-color;
    .zol-select-option{
      background-color: $off-white-color;
      padding: 5px;
      &:hover{
        background-color: $medium-color;
        color: $white-color
      }
    }
  }
  .zol-selected-card{
    height: 100%;
    padding: 3px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    color: $medium-color;
    .zol-select-options-card{
      max-height: 160px;
      overflow: auto;
      position: absolute;
      top: 100%;
      width: 100%;
      right: 0;
      z-index: 999;
      margin: 0;
      padding: 0;
      list-style: none;
      color: $medium-color;
      .zol-select-option-card{
        background-color: $off-white-color;
        padding: 5px;
        &:hover{
          background-color: $medium-color;
          color: $white-color
        }
      }
    }
  }
}