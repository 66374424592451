.donut-container {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  //margin: 0 10px;
  //display: flex;
  //align-items: center;
  //@media screen and (max-width: 768px) {
  //  flex-direction: column;
  //}
  .donut-text-center{
    text-anchor: middle;
    dominant-baseline: middle;
    font-size: 1.3em;
  }

  svg{
    display: inline-block;
  }
  .donut-legend-container{
    width: 45%;
    float: right;
    //.legend-container {
    //  display: inline-block;
    //  margin: 20px 0 0;
    //  width: 100%;
    //  @media screen and (max-width: 768px) {
    //    margin: 0;
    //  }
    //}
  }

}
