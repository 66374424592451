@import "../../../assets/variables";

.zol-modal-overlay{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 5;
  #zol-modal{
    max-height: calc(100vh - 50px);
    margin: 15px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    .zol-modal-header{
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close-icon{
        float: right;
        right: 0px;
        cursor: pointer;
      }
    }
    .zol-modal-content{
      max-height: calc(100vh - 117px);
      overflow: auto;
      padding: 10px 15px;
    }
  }
}