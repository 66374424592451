.my-account-container{
    background-color: rgb(237, 242, 245);
    padding-top: 71px; // this part falls behind the header
    min-height: 100%;

    .my-account{
      padding: 50px 75px;
      .my-account-content{
        background-color: white;
        padding: 40px 20px;
        max-width: 1200px;
      }
    }

}