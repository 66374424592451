.progress-container {
    color: #000;
  
    .progress-text {
      margin: 0
    }
  
    .progress-container-left {
      margin: 0 5px;
      padding: 10px;
      flex: 1 1 50%;
      background-color: #eff2f9;
      position: relative;
    }
    
    .progress-container-right {
      background-color: #eff2f9;
      position: relative;
      margin: 0 5px;
      padding: 10px;
      flex: 1 1 50%;
    }
  }