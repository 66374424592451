@import "../../../assets/variables";

.zol-audit-review-step, .zol-audit-step{
    background-color: $white-color;
    padding: 50px 20px;
    margin: 40px 0px;

    .zol-form-element {
        margin-top: 30px;

        .zol-form-element-input{
            .zol-single-item-container{
                width: 25%;
                margin-top: 10px;
                display: inline-block;
                text-align: right;

                .zol-single-select{
                    margin: 0px;
                }

                .zol-single-input{
                    width: 100%;
                    max-width: 150px;
                    font-size: 110%;
                }

            }
            .zol-form-element-input{
                margin-top: 0px;
                text-align: left;

                .zol-single-item-container{
                    margin-top: 0px;
                    text-align: left;
                }

                .zol-form-element-title, .zol-form-element-wide .zol-form-element-title{
                    font-size: 100%;
                    color: $medium-color;
                }
            }
        }
        .zol-form-element-wide{
            .zol-single-item-container{
                width: 100%;
                max-width: 100%;
            }
        }
    }
    .zol-form-element-title, .zol-form-element-wide .zol-form-element-title{
        font-size: 120%;
        font-weight: normal;
        color: $dark-color;
    }

    .zol-form-element-error-text{
        display: block;
        text-align: left;
        font-size: 110%;
    }
    .zol-form-element-error-text:before{
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background: transparent url('../../../assets/icons/error_info.svg') center center 
                no-repeat;
    }
    
}

.zol-audit-review-step{

    .zol-form-element {
        .zol-form-element-input{
            border-top: 1px solid $light-color;

            .zol-form-element-input{
                border-top: none;
                .zol-single-item-container{
                    margin-top: 0px;
                }
            }
        }
        
    }

    .zol-warning{
        position: relative;
        float: right;
    }
    .zol-warning .zol-tooltiptext {
        display: none;
    }
    
    .zol-warning:hover .zol-tooltiptext {
        display: block;
        position: absolute;
        width: 300px;
        right: 25px;
        top: -15px;
        background-color: white;
        border: 1px solid #aaa;
        color: #4B4B4B;
        text-align: left;
        font-style: normal;
        border-radius: 6px;
        padding: 5px 10px;
    }
}


.zol-audit-panel{
    margin: 40px 0px;
}
.zol-audit-panel .zol-ui-progress-steps-container{
    padding: 40px 0px;
}

.zol-audit-panel .zol-stepper-footer{
    padding: 0px 40px;
}

.zol-audit-step{
    padding: 20px 40px;
    
}

.zol-audit-table{ 
    .zol-table .zol-table-header .zol-table-header-item{
        padding: 0px 10px;
        &:first-child{
            padding-left: 15px;
        }
        &:last-child{
            padding-right: 15px;
        }
    }
    .zol-table .zol-table-body .zol-table-row .zol-table-cell {
        padding: 20px 10px;

        &:first-child{
            padding-left: 15px;
        }
        &:last-child{
            padding-right: 15px;
        }
    }
    .zol-single-select{
        /* The indicator selects on top of the table can become very wide, which stretches the table out of
           the container. Giving them a fixed width is a temporarily solution, until we have a better one */
        max-width: 150px;
    }
    
}
