@import '../../../assets/variables.scss';

.zol-dashboard-config-cards{
    
    .zol-title{
        display: flex;
    }

    h2{
        color: $medium-color;
        font-size: 16px;
        font-weight: normal;
        text-transform: uppercase;
        display: block;
        white-space: nowrap;
        margin-right: 20px;
    }

    .zol-title-line{
        width: 100%;
        &:after{
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid $light-color;
        }
    }

    .zol-title-order{
        min-width: 80px;

        >span{
            display: inline-block;
            width: 20px;
            height: 100%;
            cursor: pointer;
            background: transparent url('../../../assets/icons/arrow-up.svg') center center no-repeat;
            background-size: contain;
            margin-left: 20px;
        }

        .zol-title-order-down{
            cursor: pointer;
            background-image: url('../../../assets/icons/arrow-down.svg');
        }
    }

    .zol-dashboard-config-card{
        position: relative;
        background-color: #e6e9eb;
        font-size: 16px;
        display: inline-flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 100px 20px 0px 20px;
        width: 250px;
        height: 250px;
        margin: 5px;
        cursor: pointer;

        h3{
            margin: 5px 0px;
            color: $mediumdark-color;
        }
        p{
            margin: 5px 0px;
            color: $medium-color;
            font-size: 90%;
        }
    }
    .zol-dashboard-config-card-small{
        width: 170px;
        height: 170px;
        padding-top: 70px;
    }
    .zol-dashboard-config-card-medium{
        width: 250px;
        height: 200px;
        padding-top: 70px;
     }
    .zol-dashboard-config-card-large{
        width: 250px;
        height: 200px;
        padding-top: 70px;
     }
}

.zol-dashboard-config-card-toggle-active{
    background-color: rgb(226, 231, 252);
    border-radius: 50px;
    position: absolute;
    display: block;
    top: 10px;
    right: 10px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    text-align: center;
    line-height: 50px;
    border: 2px solid white;

    >svg{
        /* TODO: check if this sizing works well cross-browser */
        width: 60%;
        height: 50px;
        
    }
}

.zol-dashboard-config-card-toggle-inactive{
    background-color:#e6e6e6;
    border-radius: 50px;
    position: absolute;
    display: block;
    top: 10px;
    right: 10px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    text-align: center;
    line-height: 50px;

    >svg{
        /* TODO: check if this sizing works well cross-browser */
        width: 60%;
        height: 50px;
        path{
            fill: $white-color;
        }
    }
}

.icon{
    fill: $primary-color;
    top: -5px;
    position: relative;
    margin-left: 5px;
    width: 30px;
    height: auto;
}