@import "../../index.scss";
@import "../../assets/variables";
@import "../../assets/breakpoints";

.zol-dashboard-container{
    margin-left: 75px;
    width: calc(100% - 225px);
    padding: 131px 75px 50px 75px; // 71px of top padding falls behind header, leaving 50px
    background-color: $color-off-white;
    max-width: 100%;
    overflow: hidden;
    @include for-xs-only {
        padding: 131px 10px 50px;
        width: calc(100% - 95px);
    }
    @include for-sm-only {
        padding: 50px 37.5px;
        width: calc(100% - 150px);
    }
}

.zol-dashboard-content{
    // background-color: rgb(237, 242, 245);
    padding: 20px 0;
    width: 100%;
}

.zol-dashboard-team-select{
    .zol-selected{
        background-color: #818793;
        color: #fff;
    }
}