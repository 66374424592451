@import "../../../assets/variables";

.zol-ui-progress-steps-container{
  width: 100%;
  min-height: 0;
  background-color: $white-color;
  .zol-ui-progress-steps-horizontal {
    display: table;
    width: 100%;
    margin: 0 auto;
    .zol-step-item{
      display: table-cell;
      position: relative;
      padding-top: 24px;

      .zol-step-circle{
        margin: 0 auto;
        background-color: $off-white-color;
        border-radius: 50%;
        text-align: center;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .zol-step-circle-button{
        cursor: pointer;
        &:hover{
          opacity: .85;
        }
      }

      .zol-step-circle-completed{
        background-color: $primary-color;
        color: $white-color;
      }

      .zol-step-title{
        color: $light-color;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        word-break: break-all;
        text-align: center;
        margin: auto;
        width: 200px;

      }

      .zol-step-left-line{
        position: absolute;
        height: 1px;
        border-top: 1px solid $light-color;
        left: 0;
        right: 50%;
      }
      .zol-step-right-line{
        position: absolute;
        height: 1px;
        border-top: 1px solid $light-color;
        right: 0;
        left: 50%;
      }
      .zol-step-line-completed{
        border-top: 1px solid $primary-color
      }
    }
  }
  .zol-ui-progress-steps-content{
    padding: 10px;
    .zol-ui-progress-steps-footer{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
    }
  }

}
