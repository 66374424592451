// TODO: Check how to deal with innerHTML's that have animations.
//       If chart has animations it becomes harder to export, because the initial values are invisible and
//       css is added later it doesn't processs te animation.
.zol-chart-trend{
  .trend-path {
    fill: none;
    //stroke-dasharray: 2000;
    //stroke-dashoffset: 2000;
    //animation: drawLine 2s linear forwards;
  }
  

  @keyframes drawLine {
    to {
      stroke-dashoffset: 0;
    }
  }
  .trend-circle {
    opacity: 1;
    //animation: showDots 1s linear 1s forwards;
  }

  @keyframes showDots {
    to {
      opacity: 1
    }
  }
  .y-axis{
    g{
      path{
        display: none;
      }
      text{
        fill: #999;
      }
      line{
        fill: #999;
        stroke-width: .5;
      }
    }
  }

  .x-axis{
    g{
      line{
        stroke: #999;
        shape-rendering: crispEdges;
        stroke-opacity: .8;
      }
      path{
        display: none;
      }
      text{
        fill: #999;
      }
    }
  }
}