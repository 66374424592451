/*
  This file keeps track of the breakpoints we are using in CSS. This way it will be easy to modify all of
  the same breakpoint in once. It is using the same breakpoints as we use inside the SizedApp component.
 */

@mixin for-xs-only {
    @media (max-width: 575px) { @content; }
}

@mixin for-sm-up {
    @media (min-width: 576px) { @content; }
}

@mixin for-sm-down {
    @media (max-width: 767px) { @content; }
}

@mixin for-sm-only {
    @media (min-width: 576px) and (max-width: 767px) { @content; }
}

@mixin for-md-up {
    @media (min-width: 768px) { @content; }
}

@mixin for-md-down {
    @media (max-width: 991px) { @content; }
}

@mixin for-md-only {
    @media (min-width: 768px) and (max-width: 991px) { @content; }
}

@mixin for-md-up {
    @media (min-width: 992px) { @content; }
}

@mixin for-xl-down {
    @media (max-width: 1199px) { @content; }
}

@mixin for-xl-only {
    @media (min-width: 993px) and (max-width: 1199px) { @content; }
}

@mixin for-xl-up {
    @media (min-width: 993px) { @content; }
}

@mixin for-xl-down {
    @media (max-width: 1599px) { @content; }
}

@mixin for-xl-only {
    @media (min-width: 992px) and (max-width: 1599px) { @content; }
}

@mixin for-xxl-up {
    @media (min-width: 1600px) { @content; }
}

@mixin for-xxl-down {
    @media (max-width: 1600px) { @content; }
}