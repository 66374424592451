@import "../../index.scss";
@import "../../assets/breakpoints.scss";

.zol-header{
    position: fixed;
    z-index: 2;
    align-items: center;
    height: 70px;
    width: 100%;
    background-color: #fff;
    border-bottom: $color-light 1px solid;

    >img{
        margin: 10px 15px;
    }
}

.zol-header-nav{
    float: right;

    > ul{
        display: inline-block;
        margin: 0px;
    }
}

.zol-header-menu-mobile{
    border-top: 1px solid grey;
    background-color: #FFF;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.2);
    display: 'flex';
    flex-direction: 'column';
}

.zol-header-item{
    display: inline-block;
    height: 50px;
    padding: 10px 20px;
    line-height: 50px;
    cursor: pointer;

    svg {
        fill: $color-dark;
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    &.zol-active{
        color: $color-primary;
    }

    &:hover, &.zol-open{
        background-color: $color-off-white;
    }

    &.zol-header-profile{
        position: relative;
        margin-left: 50px;  

        > svg{
            margin: -3px 10px 0px 0px;
            @include for-xs-only {
                margin: 0px;
            }
        }

        @include for-xs-only {
            margin-left: 0;
        }

        em{
            font-style: normal;
        }

        @include for-xs-only {
            em{
                display: none;
            }
        }

        .zol-user-menu{
            position: absolute;
            display: block;
            height: 30px;
            right: 0px;
            top: 70px;
            padding: 10px 30px 10px 10px;
            line-height: 30px;
            width: calc(100% - 41px); /* width minus padding and border width */
            min-width: 120px;
            background: white;
            background-size: 18px;
            border-width: 0px 0px 1px 1px;
            border-style: solid;
            border-color: $color-light;
            z-index: 1;

            &:hover{
                background-color: $color-off-white;
            }

        }
        
    }
}

.zol-header-menu-mobile .zol-header-item{
    display: block;
}

.zol-header-icon{
    color: $color-dark;
}