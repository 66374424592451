@import "../../../assets/variables";

// select element style
.zol-single-select{
    -webkit-appearance: none; /* Webkit */
    -moz-appearance: none; /* FF */
    -ms-appearance: none; /* Edge */
    appearance: none; /* Future */
    &::-ms-expand {
        border: none;
    }
    display: block;
    max-width: 100%;
    outline: none;
    border: none;
    background: $off-white-color url('../../../assets/icons/down.svg') calc(100% - 5px) center no-repeat;
    padding: 5px 20px 5px 5px;
    margin: 0px 0px 0px 5px;
    cursor: pointer;
}

textarea.zol-single-input {
    font-family: "Lato", sans-serif;
    min-height: 60px;
}

.zol-radio-button{
    border: none;
    padding: 7px 25px;
    margin: 5;
    border-radius: 7.5px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    cursor: pointer;
}