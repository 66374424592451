.zol-dashboard-filter{
    font-size: 14px;
    margin-top: 20px;

    .zol-form-element .zol-form-element-input .zol-single-item-container{
        width: 65%;
    }

    .zol-form-element .zol-form-element-title{
        width: 35%;
    }
}


.zol-dashboard-filter-group, .zol-dashboard-filter-period{
    max-width: 100%;
    display: inline-block;
    margin-right: 50px;

    select.zol-single-select{
        height: 50px;
        font-size: 150%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.zol-pdf-export select.zol-single-select{
    background-color: white !important;
}

.zol-dashboard-filter-period{
    max-width: 200px;
    margin-right: 0px;
}
.tooltip{
    position: relative;
   // display: inline-block;
    float: right;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }