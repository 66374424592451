@import '../../../assets/variables.scss';
@import '../../../assets/breakpoints.scss';
.zol-measures-content{
    background-color: rgb(237, 242, 245);
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.zol-measures-card{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
    position: relative;
    background-color: white;
    font-size: 16px;
    display: inline-block;
    box-sizing: border-box;
    padding: 15px;
    width: calc(25% - 20px);
    height: 250px;
    margin: 10px;
    justify-content: space-between;
    align-items: flex-end;
    @include for-md-down{
        width: calc(50% - 20px);
    }
    @include for-xs-only{
        width: calc(100% - 20px);
    }
    
    h2{
        margin: 5px 0;
        font-size: 125%;
        color: $dark-color;
    }

    p{
        margin: 5px 0px;
        color: $mediumdark-color;
    }

    h4{
        display: inline;
        text-align: left;
        font-size: 100%;
        margin: 5px 0px;
        color: $dark-color;
    }
    .zol-measures-card-tips{
        height: 20%;
        margin: 10px 0;
        position: relative;
        cursor: pointer;
    }
    .arrow{
        right: 0;
        margin-right: 10px;
        position: absolute;
        color: $dark-color;
    }
    .info-container{
        cursor: pointer;
        color: #2e5bff;
        background-color: #efefef;
        display: inline-block;
        border-radius: 100%;
        justify-content: center;
        width: 25px;
        position: relative;
        height: 0;
        padding-bottom: 25px;
        margin-left: 6px;
        vertical-align: middle;
    }
    .selected{
        color: #efefef;
        background-color: #2e5bff;
    }
    .more-info-container{
        position: absolute;
        background-color: #fff;
        margin-top: 15px;
        padding: 8px;
        font-size: 12px;
        right: -5px;
        border: 1px solid #ccc;
        min-width: 200px;
        border-radius: 5px;
        &:before{
            content: "";
            vertical-align: middle;
            margin: auto;
            position: absolute;
            display: block;
            right: 10px;
            bottom: calc(100% - 6px);
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            border: 1px solid;
            border-color: #ccc transparent transparent #ccc;
            background-color: white;
        }
    }
}

.zol-measures-tip{
    h3 {
        font-weight: normal;
        font-style: italic;
        margin: 10px 0;
        > span{
            color: $light-color;
            font-style: normal;
            font-size: 90%;
            margin-left: 20px;
            float: right;
        }
    }
    p{
        margin: 5px 0px 30px 0px;
    }
}