/*
  This file keeps track of the common styles we are using. This way it will be easy to modify all of the same 
  colors in once. To use these variables you can simply import this file to the scss file like `@import 
  '../../assets/variables.scss';`
  usage: `background-color: $primary-color `
 */

 /* TODO: Remove this file. The properties have been moved to index.scss and renamed, this files is 
    depricated and should eventually be removed */

$white-color: #fff; //for navigation card / background
$off-white-color: #edf2f5; //for input background, dashboard background
$light-color: #C0C3C9; //for disabled text/icon, border line
$medium-color: #818793; //for body text, unselected text, legend text
$mediumdark-color: #737373;
$dark-color: #2E384D; //for title text, icons

$primary-color: #2E5BFF; //for highlighting, button text, button background
$brand-blue-color: #1EAFC5; //for charts
$brand-light-blue-color: #8CD7E1; //for charts
$brand-yellow-color: #FABB18; //for charts
$brand-red-color: #E84A50; //for charts
$brand-green-color: #9fae20; //for charts
$brand-purple-color: #6E0E75; //for charts



// :export is the magic sauce to be able to use these variables in JS code
// To use these variables you can simply import this file to the js file like `import colorVariables from 'path-to-this-file'`
// usage: `backgroundColor: colorVariables.whiteColor`
:export {
  whiteColor: $white-color;
  offWhiteColor: $off-white-color;
  lightColor: $light-color;
  mediumColor: $medium-color;
  mediumDarkColor: $mediumdark-color;
  darkColor: $dark-color;

  primaryColor: $primary-color;
  brandBlueColor: $brand-blue-color;
  brandLightBlueColor: $brand-light-blue-color;
  brandYellowColor: $brand-yellow-color;
  brandRedColor: $brand-red-color;
  brandGreenColor: $brand-green-color;
  brandPurpleColor: $brand-purple-color;


}
