@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

 //300 ligth, 700 is bold

$color-white: #fff; //for navigation card / background
$color-off-white: #edf2f5; //for input background, dashboard background
$color-light: #C0C3C9; //for disabled text/icon, border line
$color-medium: #818793; //for body text, unselected text, legend text
$color-mediumdark: #737373;
$color-dark: #2E384D; //for title text, icons

$color-primary: #2E5BFF; //for highlighting, button text, button background
$color-blue: #1EAFC5; //for charts
$color-light-blue: #8CD7E1; //for charts
$color-yellow: #FABB18; //for charts
$color-red: #E84A50; //for charts
$color-green: #9fae20; //for charts
$color-purple: #6E0E75; //for charts



// :export is the magic sauce to be able to use these variables in JS code
// To use these variables you can simply import this file to the js file like `import colorVariables from 'path-to-this-file'`
// usage: `backgroundColor: colorVariables.whiteColor`
:export {
  colorWhite: $color-white;
  colorOffWhite: $color-off-white;
  colorLight: $color-light;
  colorMedium: $color-medium;
  colorMediumDark: $color-mediumdark;
  colorDark: $color-dark;

  colorPrimary: $color-primary;
  colorBlue: $color-blue;
  colorLightBlue: $color-light-blue;
  colorYellow: $color-yellow;
  colorRed: $color-red;
  colorGreen: $color-green;
  colorPurple: $color-purple;
}

.notransition {
  *{
    animation: none !important;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: 'Lato', sans-serif;
}

.zol-feedback-toggle{
    position: absolute;
    background-color: $color-red;
    height: 150px;
    width: 40px;
    top: 50%;
    right: 0;
    cursor: pointer;

    > span{
        display: block;
        width: 150px;
        transform: rotate(-90deg) translate(-63px, -57px);
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        color: white;
    }
}