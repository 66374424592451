.head-1{
  font-size: 34px;
  font-weight: 400;
}

.head-2{
  font-size: 28px;
  font-weight: 400;
}

.head-3{
  font-size: 24px;
  font-weight: 700;
}
.head-4{
  font-size: 18px;
  font-weight: 400;
}
.head-5{
  font-size: 26px;
  font-weight: 500;
}

.head-6{
  font-size: 18px;
  font-weight: 400;
}

.body-L-bold{
  font-size: 16px;
  font-weight: 700;
}

.body-L{
  font-size: 16px;
  font-weight: 500;
}

.body-M-bold{
  font-size: 14px;
  font-weight: 700;
}

.body-M{
  font-size: 14px;
  font-weight: 500;
}

.body-S-bold{
  font-size: 12px;
  font-weight: 700;
}

.body-S{
  font-size: 12px;
  font-weight: 500;
}

.body-XS {
  font-size: 10px;
  font-weight: 500;
}