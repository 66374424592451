@import "../../assets/variables";

.zol-side-bar{
    transition: width 0.2s;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 71px;
    // height: calc(100% - 70px);
    bottom: 0px;
    background-color: #fff;
    .zol-side-bar-item{
        justify-content: flex-start;
        padding-left: 16px;
        border-left-style: solid;
        border-left-width: 5px;
        align-items: center;
        cursor: pointer;
        height: 60px;
        display: flex;
        border-left-color: #fff;
        background-color: #fff;
        color: $light-color;
        &.active-section{
            color: $primary-color;
        }
    }
}

.zol-side-bar-open{
    width: 200px;
    z-index: 2;
}
.zol-side-bar-close{
    width: 75px
}

.zol-side-bar-section{
    bottom: 0px;
    position: absolute;
}