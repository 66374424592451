@import "../../../assets/variables";

.zol-table{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  .zol-table-header{
    border-bottom: 1px solid #e5e5e5;
    padding: 0 4px 10px;
    .zol-table-header-item{
      padding-left: 0;
      padding-right: 0;
      text-align: left;
      padding-bottom: 10px;
      color: $medium-color;
      &:first-child{
        padding-left: 4px;
      }
      &:last-child{
        padding-right: 4px;
      }
    }
  }
  .zol-table-body{
    .zol-table-row{
      background-color: #fff;
      .zol-table-cell{
        text-align: left;
        padding: 20px 0;
        &:first-child{
          padding-left: 15px;
        }
        &:last-child{
          padding-right: 15px;
        }
      }
    }
  }

  .zol-table-footer{
    .show-more {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      text-align: center;
      &:before, &:after{
        content: '';
        flex: 1;
        border-bottom: 1px solid $medium-color;
      }
      &:before{
        margin-right: 25px;
      }
      &:after{
        margin-left: 25px;
      }
    }
  }
}