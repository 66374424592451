.zol-chart-vertical-bar{
  .y-axis{
    g{
      path{
        display: none;
      }
      text{
        fill: rgba(153,153,153,.65);
      }
      line{
        fill: rgba(153,153,153,.65);
        stroke-width: .5;
      }
    }
  }
  .x-axis{
    g{
      line, path{
        display: none;
      }
      text{
        fill: rgba(153,153,153,.65);
      }
    }
  }
}

.zol-chart-vertical-bar-axis-label{
  position: relative;
  height: 20px;
  bottom: 20px;
  margin-left: 50px;
  .icon{
    position: absolute;
    height: 20px;
    top: 5px;
    width: 20px;
    .header{
      font-weight: bold;
      font-size: 1em;
      margin: 0px 0px 5px 0px;
    }
    .text{
      margin: 0;
      font-size: 0.8em;
      white-space: pre-wrap;
    }
  }
  .icon div{
    visibility: hidden;
  }
  .icon:hover div{
    visibility: visible;
    display: block;
    min-width: 150px;
    position: absolute;
    max-width: 300px;
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 7px 12px;
    font-family: "FS Me Web Regular", ABeeZee, Helvetica, Arial, Verdana, sans-serif;
    color: #4B4B4B;
    z-index: 2;
  }

}